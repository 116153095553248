import { computed, onMounted } from 'vue'
import { get, includes } from 'lodash'
import { useLazyI18n } from '@/plugins/lazy-i18n'
import { EstablishmentTypeEnum, CategoryEnum, ServicesFiltersEnum } from '@/enums'
import { useSearchFiltersStore } from '@/stores/searchFilters'
import { useCategoriesStore } from '@/stores/categories'
import translations from './Lang'

export function useEstablishmentTypeFilter(isMobile = false) {
  /**
   * Some initializations
   */
  const { $lt } = useLazyI18n(translations)
  const searchFiltersStore = useSearchFiltersStore()
  const categoriesStore = useCategoriesStore()

  /**
   * [Computed] Traductions
   */
  const lang = computed(() => ({
    label: $lt('label'),
    placeholder: $lt('placeholder'),
    all: $lt('all'),
    withPharmacy: $lt('withPharmacy'),
    withPrivateClinic: $lt('withPrivateClinic'),
    withHealthEstablishment: $lt('withHealthEstablishment'),
    others: $lt('others')
  }))

  /**
   * [Computed] Returns the 'other text'
   */
  const otherText = computed(() => currentValue.value.length - 1 + ' ' + lang.value.others)

  /**
   * [Computed] Returns the list of filters
   * @description : Has TU
   */
  const filtersList = computed(() => [
    {
      id: EstablishmentTypeEnum.ALL,
      key: ServicesFiltersEnum.WITH_ALL_ESTABLISHMENTS_TYPE,
      title: lang.value.all,
      props: { disabled: isFiltersDisable.value }
    },
    {
      id: EstablishmentTypeEnum.HEALTH_ESTABLISHMENT,
      key: ServicesFiltersEnum.WITH_HEALTH_ESTABLISHMENT,
      title: lang.value.withHealthEstablishment,
      props: {
        avatar: './img/fleur-de-lys.svg',
        disabled: isFiltersDisable.value
      }
    },
    {
      id: EstablishmentTypeEnum.PHARMACY,
      key: ServicesFiltersEnum.WITH_PHARMACY,
      title: lang.value.withPharmacy,
      props: { disabled: isFiltersDisable.value }
    },
    {
      id: EstablishmentTypeEnum.PRIVATE_CLINIC,
      key: ServicesFiltersEnum.WITH_PRIVATE_CLINIC,
      title: lang.value.withPrivateClinic,
      props: { disabled: isFiltersDisable.value }
    }
  ])

  /**
   * [Computed] Returns the active filter
   * @description : Has TU
   */
  const activeFilters = computed(() => {
    if (isMobile) {
      if (
        searchFiltersStore.mobile.withPrivateClinic &&
        searchFiltersStore.mobile.withPharmacy &&
        searchFiltersStore.mobile.withHealthEstablishment
      )
        return [
          EstablishmentTypeEnum.PRIVATE_CLINIC,
          EstablishmentTypeEnum.PHARMACY,
          EstablishmentTypeEnum.HEALTH_ESTABLISHMENT
        ]
      else {
        return [
          ...(searchFiltersStore.mobile.withPrivateClinic
            ? [EstablishmentTypeEnum.PRIVATE_CLINIC]
            : []),
          ...(searchFiltersStore.mobile.withPharmacy ? [EstablishmentTypeEnum.PHARMACY] : []),
          ...(searchFiltersStore.mobile.withHealthEstablishment
            ? [EstablishmentTypeEnum.HEALTH_ESTABLISHMENT]
            : [])
        ]
      }
    } else {
      if (
        searchFiltersStore.withPrivateClinic &&
        searchFiltersStore.withPharmacy &&
        searchFiltersStore.withHealthEstablishment
      )
        return [
          EstablishmentTypeEnum.PRIVATE_CLINIC,
          EstablishmentTypeEnum.PHARMACY,
          EstablishmentTypeEnum.HEALTH_ESTABLISHMENT
        ]
      else {
        return [
          ...(searchFiltersStore.withPrivateClinic ? [EstablishmentTypeEnum.PRIVATE_CLINIC] : []),
          ...(searchFiltersStore.withPharmacy ? [EstablishmentTypeEnum.PHARMACY] : []),
          ...(searchFiltersStore.withHealthEstablishment
            ? [EstablishmentTypeEnum.HEALTH_ESTABLISHMENT]
            : [])
        ]
      }
    }
  })

  /**
   * [Computed] Returns the current value
   */
  const currentValue = computed({
    get: () => {
      if (activeFilters.value.length === 3) return filtersList.value[0]
      return filtersList.value.filter((item) => {
        if (includes(activeFilters.value, item.id)) return item
      })[0]
    },
    set: (value) => {
      setEstablishmentTypeFilter(value)
    }
  })

  /**
   * [Computed] Returns true if filters have to be disabled, false othersiwe
   */
  const isFiltersDisable = computed(
    () =>
      get(categoriesStore.currentCategory, 'name') === CategoryEnum.CATEGORY_PHARMACY_CONSULTATION
  )

  /**
   * [Computed] Updates the right filter state
   * @description : Has TU
   */
  const setEstablishmentTypeFilter = (item: any) => {
    if (!isMobile) {
      searchFiltersStore.withPharmacy =
        item?.key === ServicesFiltersEnum.WITH_PHARMACY ||
        item?.key === ServicesFiltersEnum.WITH_ALL_ESTABLISHMENTS_TYPE
      searchFiltersStore.withPrivateClinic =
        item?.key === ServicesFiltersEnum.WITH_PRIVATE_CLINIC ||
        item?.key === ServicesFiltersEnum.WITH_ALL_ESTABLISHMENTS_TYPE
      searchFiltersStore.withHealthEstablishment =
        item?.key === ServicesFiltersEnum.WITH_HEALTH_ESTABLISHMENT ||
        item?.key === ServicesFiltersEnum.WITH_ALL_ESTABLISHMENTS_TYPE
    }
    searchFiltersStore.mobile.withPharmacy =
      item?.key === ServicesFiltersEnum.WITH_PHARMACY ||
      item?.key === ServicesFiltersEnum.WITH_ALL_ESTABLISHMENTS_TYPE
    searchFiltersStore.mobile.withPrivateClinic =
      item?.key === ServicesFiltersEnum.WITH_PRIVATE_CLINIC ||
      item?.key === ServicesFiltersEnum.WITH_ALL_ESTABLISHMENTS_TYPE
    searchFiltersStore.mobile.withHealthEstablishment =
      item?.key === ServicesFiltersEnum.WITH_HEALTH_ESTABLISHMENT ||
      item?.key === ServicesFiltersEnum.WITH_ALL_ESTABLISHMENTS_TYPE
  }

  /**
   * [Computed] Clears all filters
   * @description : Has TU
   */
  const clearFilters = () => {
    searchFiltersStore.withPharmacy = false
    searchFiltersStore.withPrivateClinic = false
    searchFiltersStore.withHealthEstablishment = false
  }

  /**
   * [Computed] Applies all mobile filters to main filters
   * @description : Has TU
   */
  const applyMobileFilters = () => {
    searchFiltersStore.withPharmacy = searchFiltersStore.mobile.withPharmacy
    searchFiltersStore.withPrivateClinic = searchFiltersStore.mobile.withPrivateClinic
    searchFiltersStore.withHealthEstablishment = searchFiltersStore.mobile.withHealthEstablishment
  }

  /**
   * [Computed] Cancels all mobile filters by main filters
   * @description : Has TU
   */
  const cancelMobileFilters = () => {
    searchFiltersStore.mobile.withPharmacy = searchFiltersStore.withPharmacy
    searchFiltersStore.mobile.withPrivateClinic = searchFiltersStore.withPrivateClinic
    searchFiltersStore.mobile.withHealthEstablishment = searchFiltersStore.withHealthEstablishment
  }

  /**
   * [Computed] Clears all mobile filters (all are turned to false)
   * @description : Has TU
   */
  const clearMobileFilters = () => {
    searchFiltersStore.mobile.withPharmacy = false
    searchFiltersStore.mobile.withPrivateClinic = false
    searchFiltersStore.mobile.withHealthEstablishment = false
  }

  /**
   * [Function] Applies filter
   * @param place
   * @returns
   */
  const filter = (place: any): boolean => {
    let check = true
    if (activeFilters.value.length > 0) {
      check = includes(activeFilters.value, place.establishmentType)
    }
    return check
  }

  onMounted(() => {
    if (
      get(categoriesStore.currentCategory, 'name') === CategoryEnum.CATEGORY_PHARMACY_CONSULTATION
    ) {
      searchFiltersStore.withPharmacy = true
      searchFiltersStore.mobile.withPharmacy = true
    }
  })

  return {
    lang,
    otherText,
    filtersList,
    activeFilters,
    currentValue,
    isFiltersDisable,
    setEstablishmentTypeFilter,
    clearFilters,
    applyMobileFilters,
    cancelMobileFilters,
    clearMobileFilters,
    filter
  }
}
